<template>
  <b-modal 
    :id="modalId"
    ref="executiveAddRiskOppsMonth"
    title="Add Risk / Opps"
    centered 
    no-close-on-backdrop
    hide-footer
    hide-header-close
  >
    <div style="display: flex; flex-direction: row; height: 50%" >
      <validation-observer #default="{ handleSubmit }" ref="refExecutiveFormObserver" style="width: 50%;" >
        <b-form id="executive-add-risk-opps-month-form" class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
          <!-- Type -->
          <validation-provider #default="validationContext" name="Type" rules="required">
            <b-form-group label="Type" label-for="Type" :state="getValidationState(validationContext)">
              <v-select v-model="newRowData.type" :options="typeOptions" :reduce="val => val.value" input-id="executive-type" />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Brand -->
          <validation-provider #default="validationContext" name="Brand" rules="required">
            <b-form-group label="Brand" label-for="brand">
              <b-form-select v-model="newRowData.brand" :options="brandOptions" input-id="executive-brand" />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Month -->
          <validation-provider #default="validationContext" name="Month" rules="required">
            <b-form-group label="Month" label-for="Month" :state="getValidationState(validationContext)">
              <v-select v-model="newRowData.month" :options="monthOptions" :reduce="val => val.value" input-id="executive-month" placeholder="Select Month" />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Desipcription -->
          <validation-provider #default="validationContext" name="Description" rules="required">
            <b-form-group label="Description" label-for="description" >
              <b-form-input id="description" v-model="newRowData.description" :state="getValidationState(validationContext)" trim />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Value -->
          <validation-provider #default="validationContext" name="value" rules="required">
            <b-form-group label="value" label-for="value" >
              <b-form-input id="value" v-model="newRowData.value" :state="getValidationState(validationContext)" trim />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2 justify-content-center">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit"> Add </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary" @click="onCancel"> Close </b-button>
          </div>

        </b-form>
      </validation-observer>

      <div class="list-riskopps" style="width: 50%; height: 100%;">
        <b-button
          style="margin-left: auto;"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'" 
          variant="danger" 
          size="sm"
          type="button"
          :disabled="selectedRow==null"
          @click="onDeleteSelectedRow"
        > Delete Risk/Opp </b-button>
        <div class="table-wrapper-modal">
          <b-table
            :items="items"
            :fields="fields"
            :select-mode="'single'"
            :current-page="currentPage"
            :per-page="perPage"
            :busy="isLoading"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            @row-selected="onRowSelected"
            responsive="sm"
            ref="selectableTable"
            class="custom-table"
            selectable
            small
            outlined
          />

          <div v-if="isLoading" class="overlay">
            <b-spinner variant="primary" class="spinner" />
          </div>

        </div>
        
        <b-pagination
          style="position: absolute; bottom: 7%; width: 50%"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </div>

    </div>
    
  </b-modal>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormSelect, BPagination, BSpinner,
  BInputGroup, BInputGroupAppend, BTable
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, getCurrentInstance, toRefs, toRef } from 'vue'
import { required, alphaNum } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import gql from 'graphql-tag'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BTable,
    BPagination,
    BSpinner,

    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    year: {
      type: Number,
      required: true,
    },
    brandOptions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      required,
      alphaNum,
    }
  },
  setup(props, { emit }) {
    const modalId = ref(`executive-add-risk-opps-month`)
    const vm = getCurrentInstance().proxy

    const { id, year, brandOptions } = toRefs(props)
    const items = ref([ /* { type: 'Risk', bpu: 'Cosmetics', brand: 'Nivea Body Care', descripcion: 'RiskTest', month: 1, value: 10 }, */ ])
    const totalRows = ref(items.value.length)
    const currentPage = ref(1)
    const sortBy = ref('bpu')
    const sortDesc = false
    const perPage = ref(10) 
    const isLoading = ref(false)
    const selectedRow = ref(null)
    
    const blankFiltersData = { brand: '', type: 'Risk', month: 1, description: ' ', value: 0 }
    

    vm.$apollo.addSmartSubscription('GetRiskOpps', {
      query: gql`
        subscription MySubscription( $id: Int ) {
          riskOpps: risk_opp_brand_category( where: { id: {_eq: $id } } ) {
            tipo
            bpu
            brand
            descripcion
            month
            valor
          }
      }`,
      variables(){
        return {
          id: id.value
        }
      },
      result ({ data }) {
        for(let obj of data.riskOpps) { if (obj.__typename) delete obj.__typename }

        // console.log('data retrieved', data)
        totalRows.value = data.riskOpps.length
        isLoading.value = false
        items.value = data.riskOpps
      },
    })
    
    const typeOptions = ref([
      {"label": "Risk", "value": "Risk"},
      {"label": "Opps", "value": "Opps"}
    ])

    const monthOptions = ref([
      { label: 'Enero', value: 1 },
      { label: 'Febrero', value: 2 },
      { label: 'Marzo', value: 3 },
      { label: 'Abril', value: 4 },
      { label: 'Mayo', value: 5 },
      { label: 'Junio', value: 6 },
      { label: 'Julio', value: 7 },
      { label: 'Agosto', value: 8 },
      { label: 'Septiembre', value: 9 },
      { label: 'Octubre', value: 10 },
      { label: 'Noviembre', value: 11 },
      { label: 'Diciembre', value: 12 },
    ])

    const fields = ref([
      { key: 'tipo', label: 'Type', sortable: true },
      { key: 'bpu', label: 'BPU', sortable: true },
      { key: 'brand', label: 'Brand', sortable: true },
      { key: 'descripcion', label: 'Description', sortable: true },
      { key: 'month', label: 'Month', sortable: true, formatter: value => monthOptions.value.find(m => m.value === value).label },
      { key: 'valor', label: 'Value', sortable: true },
    ])

    const newRowData = ref(JSON.parse(JSON.stringify(blankFiltersData)))
    const resetRowData = () => {
      newRowData.value = JSON.parse(JSON.stringify(blankFiltersData))
    }

    const onSubmit = () => {
      isLoading.value = true
      vm.$apollo.mutate({
        mutation: gql`
          mutation MyMutation( $objs: [risk_opp_brand_category_insert_input!]! ) {
            insert_risk_opp_brand_category( objects: $objs ) {
              affected_rows
            }
          }
        `,
        variables: {
          objs: [{
            id: id.value,
            bpu: newRowData.value.brand.bpu,
            brand: newRowData.value.brand.brand,
            tipo: newRowData.value.type,
            descripcion: newRowData.value.description,
            year: year.value,
            month: newRowData.value.month,
            valor: newRowData.value.value
          }]
        }
      }).then( (res) => {
        resetForm()
      }).finally(() => {
        isLoading.value = false
      })
    }

    const onCancel = () => {
      resetForm()
      vm.$bvModal.hide(modalId.value)
    }

    const onRowSelected = (item) => {
      selectedRow.value = item[0]
    }

    const onDeleteSelectedRow = () => {
      isLoading.value = true
      vm.$apollo.mutate({
        mutation: gql`
          mutation MyMutation( 
            $id: Int, $year: Int, $month: Int, $valor: numeric, $bpu: String, $brand: String,
            $descripcion: String, $tipo: String
          ) {
            delete_risk_opp_brand_category( where: {
              id: { _eq: $id }, year: { _eq: $year }, month: { _eq: $month },
              bpu: { _eq: $bpu }, brand: { _eq: $brand }, descripcion: { _eq: $descripcion },
              valor: { _eq: $valor }, tipo: { _eq: $tipo },
            } ) {
              affected_rows
            }
          }`,
        variables: {
          id: id.value,
          year: year.value,
          month: selectedRow.value.month,
          tipo: selectedRow.value.tipo,
          bpu: selectedRow.value.bpu,
          brand: selectedRow.value.brand,
          descripcion: selectedRow.value.descripcion,
          valor: selectedRow.value.valor
        }
      }).then( (res) => {
        // resetForm()
        console.log(res)
      }).catch( (error) => {
        console.log({ ...selectedRow.value, id: id.value, year: year.value })
        console.error(error)
      }).finally(() => {
        isLoading.value = false
      })
    }

    const {
      refExecutiveFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetRowData)

    return {
      modalId,
      newRowData,
      onSubmit,
      onCancel,

      typeOptions,
      brandOptions,
      monthOptions,

      refExecutiveFormObserver,
      getValidationState,
      resetForm,

      items,
      fields,

      currentPage,
      perPage,
      totalRows,
      isLoading,

      onRowSelected,
      selectedRow,
      onDeleteSelectedRow,

      sortBy,
      sortDesc
    }
  },
}
</script>

<style lang="scss">

.modal {
  height: 60% !important;
}

.modal-dialog {
  max-width: 1000px !important;
}


table.b-table[aria-busy='true'] {
  opacity: 0.6;
}

.table-wrapper .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.custom-table {
  margin-top: 1rem;
  height: auto !important; /* Asegura que la altura de la tabla sea dinámica */
  max-height: 90%;
}
.custom-table tbody tr {
  height: auto !important; /* Asegura que las filas no se expandan */
}

@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';

</style>
