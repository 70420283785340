<template>
  <b-card no-body>
    <div v-if="isLoading" class="my-3">
      <custom-loading></custom-loading>
    </div>
    <div v-else style="display: flex; flex-direction: column; height: 90vh;" >

      <ExecutiveAddRiskOppsMonthVue 
        :id="parseInt(id)"
        :year="parseInt(id.slice(0,4))"
        :brandOptions="brandOptions"
      />

      <div class="header" style="background-color: #1e347b; display: grid; place-items: center; height: 7%;" >
        <h1 style="font-weight: bolder; color: white; ">Cuadro Comparativo Mensual BPU/Brand</h1>
      </div>

      <div class="">

        <b-button id="btnaddrisk" class="mx-2" variant="primary" @click="showEditRiskOpps">
          <BIconBoxArrowInUpRight/> Edit Risk/Opps
        </b-button>

        <b-dropdown text="Select Months" class="m-2" variant="primary" dropright>
          <b-dropdown-item-button class="dropdownItem" :key="option.value" @click="toggleOption(option.value, $event)" v-for="option in monthsOptions" >
            <b-form-checkbox v-model="monthsSelected" :value="option.value" :plain="true"> {{ option.text }} </b-form-checkbox>
          </b-dropdown-item-button>
        </b-dropdown>

        <!-- <b-dropdown id="filtro-months"  text="Select Months" ref="dropdown" style="width: 145px;background-color: #0032a0;color: white; margin-bottom: 2rem;margin-left: 1rem;"  variant="outline-light">
          <b-dropdown-form>
            <b-form-group>
              <b-form-checkbox-group v-model="monthsSelected">
                <b-form-checkbox v-for="option in monthsOptions" :key="option.value" :value="option.value" :plain="true"> {{ option.text }} </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </b-dropdown-form>
        </b-dropdown> -->
        
      </div>
      
      <div class="table-wrapper">
        <v-grid
          ref="refGridSopExecutiveBrand"
          :source="source"
          :columns="columns"
          :columnTypes="pluginRevo"
          theme="material"
          resize="true"
          readonly="true"
          autoSizeColumn="true"
          class="grid-container-sop-executive"
        />

        <div v-if="isTableLoading" class="overlay">
          <b-spinner variant="primary" class="spinner" />
        </div>
      </div>

    </div>
  </b-card>
</template>

<script>
import { 
  BCard, BButton, BCardBody, BFormGroup, BIconBoxArrowInUpRight, BSpinner, BDropdown, BDropdownItemButton, BFormCheckbox
} from 'bootstrap-vue'
import { ref, getCurrentInstance, inject } from 'vue'
import VGrid, { VGridVueTemplate } from '@revolist/vue-datagrid'
import CustomLoading from '@/modules/common/CustomLoading.vue'
import NumberColumnType from '@revolist/revogrid-column-numeral'
import FormatCellNumber from '../FormatCellNumber2.vue'
import ExecutiveAddRiskOppsMonthVue from './ExecutiveAddRiskOppsMonth.vue'
import gql from 'graphql-tag'

export default {
  components: {
    BCard, 
    BButton, 
    BCardBody,
    BFormGroup,

    CustomLoading,
    BSpinner,

    VGrid,
    ExecutiveAddRiskOppsMonthVue,

    BIconBoxArrowInUpRight,

    BDropdown, BDropdownItemButton, BFormCheckbox
  },
  setup(props) {
    const clearableSelect = ref(false)
    const source = ref([])
    const id = ref(null)
    const refGridSopExecutive = ref(null)
    const columns = ref([])
    const isLoading = ref(true)
    const isTableLoading = ref(true)

    const brandOptions = ref(null
      // {
      //   label: 'Cosmetics',
      //   options: [
      //     { text: 'Nivea Body Care', value: { bpu: 'Cosmetics', brand: 'Nivea Body Care' } },
      //     { text: 'Nivea Body Cleansing', value: { bpu: 'Cosmetics', brand: 'Nivea Body Cleansing' } },
      //   ]
      // },
      // {
      //   label: 'Health Care',
      //   options: [
      //     { text: 'Foot', value: { bpu: 'Health Care', brand: 'Foot' } },
      //     { text: 'Pain', value: { bpu: 'Health Care', brand: 'Pain' } },
      //   ]
      // }
    )

    const mapBrandOptions = (items) => {
      const options = []

      items.forEach( item => {
        let option = options.find( option => option.label == item.bpu )

        if ( !option ){
          options.push({ label: item.bpu, options: [{ text: item.brand, value: { bpu: item.bpu, brand: item.brand } }] })
        } else {
          options[options.indexOf(option)].options.push({ text: item.brand, value: { bpu: item.bpu, brand: item.brand } })
        }
      })

      return options
    }

    const monthsSelected = ref([])
    const monthsOptions = ref([
      { value: 1, text: 'Enero' },
      { value: 2, text: 'Febrero' },
      { value: 3, text: 'Marzo' },
      { value: 4, text: 'Abril' },
      { value: 5, text: 'Mayo' },
      { value: 6, text: 'Junio' },
      { value: 7, text: 'Julio' },
      { value: 8, text: 'Agosto' },
      { value: 9, text: 'Septiembre' },
      { value: 10, text: 'Octubre' },
      { value: 11, text: 'Noviembre' },
      { value: 12, text: 'Diciembre' }
    ])

    const yearSelected = 2024

    const pluginRevo = { 
      'numeric': new NumberColumnType('0,0'),
      'decimal': new NumberColumnType('0,0.0'),
      'percentage': new NumberColumnType('0,0[.]00 %'),
      'numeric_negative': new NumberColumnType('(0,0)'),
    }

    const vm = getCurrentInstance().proxy

    const getStyleGrid = (prop, model, column, data = null) => {
      if ( prop == "bpu" ) {
        const rowIndex = data.indexOf(model)
        // console.log('RowIndex', rowIndex)
        if ( rowIndex >= 1 ) {
          // console.log('Comparrison', data[rowIndex][prop], data[rowIndex - 1][prop] )
          // console.log('Result', data[rowIndex][prop] == data[rowIndex - 1][prop] )
          const checkDups = data[rowIndex][prop] == data[rowIndex - 1][prop]
          if ( checkDups ) {
            return {
              background: "#ebebeb",
              color: "#ebebeb"
            }
          }
        }
      }

      if(model.bpu.length == 0 && model.id.length == 0 && model.year.length == 0) {
        return {
          "border-bottom": "0px solid #ebebeb",
        }
      }

      if(model.bpu.length == 0) {
        return {
          "border-bottom": "3px solid #ebebeb",
        }
      }

      let customStyle = {
        "text-align": "left",
        "border-top": "3px solid #ebebeb",
        "border-right": "3px solid #ebebeb",
        "border-bottom": "3px solid #ebebeb",
        "border-left": "3px solid #ebebeb",
      }

      const filasSinPorcentaje = ['bpu','brand','sopeur', 'lyeur', 'fceur', 'sopwro', 'risks', 'opps']
      if( !filasSinPorcentaje.includes( column.prop.toLowerCase() ) ) {
        return {
          "text-align": "middle",
          "border-top": "3px solid #ebebeb",
          "border-right": "3px solid #ebebeb",
          "border-bottom": "3px solid #ebebeb",
          "border-left": "3px solid #ebebeb",
        }
      }
      
      if (column.prop == "bpu") {
        delete customStyle["text-align"]
        delete customStyle["border-top"]
        delete customStyle["border-left"]
        customStyle["font-weight"] = "bold"
        customStyle["background"] = "#1e347b"
        customStyle["color"] = "white"
      }

      return customStyle
    }

    columns.value = [
      {
        "name": "",
        columnProperties: ({prop, model, data, column}) => {
          return {
            style: {
              color: 'white',
              "text-align": "center",
              "background": "#1e347b",
              "font-size": "30px",
            }
          };
        },
        "children": [
          {
            "name": "BPU",
            "prop": "bpu",
            "size": 120,
            "autoSize": true,
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column, data),
              }
            },
            columnProperties: ({prop, model, data, column}) => {
              return {
                class: {
                  'revo-header': true
                }
              }
            },
          },
          {
            "name": "BRAND",
            "prop": "brand",
            "size": 300,
            "autoSize": true,
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
            columnProperties: ({prop, model, data, column}) => {
              return {
                class: {
                  'revo-header': true
                }
              }
            },
          },
          {
            "name": "SOPEUR",
            "prop": "SOPEUR",
            "size": 120,
            "autoSize": true,
            cellTemplate: VGridVueTemplate(FormatCellNumber),
            columnProperties: ({prop, model, data, column}) => {
              return {
                class: {
                  'revo-header': true
                }
              }
            },
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
          },
          {
            "name": "LYEUR",
            "prop": "LYEUR",
            "size": 120,
            "autoSize": true,
            cellTemplate: VGridVueTemplate(FormatCellNumber),
            columnProperties: ({prop, model, data, column}) => {
              return {
                class: {
                  'revo-header': true
                }
              }
            },
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
          },
          {
            "name": "FCEUR",
            "prop": "FCEUR",
            "size": 120,
            "autoSize": true,
            cellTemplate: VGridVueTemplate(FormatCellNumber),
            columnProperties: ({prop, model, data, column}) => {
              return {
                class: {
                  'revo-header': true
                }
              }
            },
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
          },
          {
              "name": "SOPvFC",
              "prop": "SOPvFC",
              "size": 100,
              "autoSize": true,
              cellTemplate: VGridVueTemplate(FormatCellNumber),
              columnProperties: ({prop, model, data, column}) => {
                  return {
                    class: {
                        'revo-header': true
                    } 
                  }
              },
              cellProperties: ({ prop, model, data, column }) => {
                  return {
                    style: getStyleGrid(prop, model, column)
                  }
              },
          },
          {
              "name": "FCvLY",
              "prop": "FCvLY",
              "size": 100,
              "autoSize": true,
              cellTemplate: VGridVueTemplate(FormatCellNumber),
              columnProperties: ({prop, model, data, column}) => {
                  return {
                  class: {
                      'revo-header': true
                  }
                  }
              },
              cellProperties: ({ prop, model, data, column }) => {
                  return {
                  style: getStyleGrid(prop, model, column)
                  }
              },
          },
          {
              "name": "RISKS",
              "prop": "RISKS",
              "size": 120,
              "autoSize": true,
              cellTemplate: VGridVueTemplate(FormatCellNumber),
              columnProperties: ({prop, model, data, column}) => {
                  return {
                  class: {
                      'revo-header': true
                  }
                  }
              },
              cellProperties: ({ prop, model, data, column }) => {
                  return {
                  style: getStyleGrid(prop, model, column)
                  }
              },
          },
          {
              "name": "OPPS",
              "prop": "OPPS",
              "size": 120,
              "autoSize": true,
              cellTemplate: VGridVueTemplate(FormatCellNumber),
              columnProperties: ({prop, model, data, column}) => {
                  return {
                  class: {
                      'revo-header': true
                  }
                  }
              },
              cellProperties: ({ prop, model, data, column }) => {
                  return {
                  style: getStyleGrid(prop, model, column)
                  }
              },
          },
          {
              "name": "SOPwRO",
              "prop": "SOPwRO",
              "size": 100,
              "autoSize": true,
              cellTemplate: VGridVueTemplate(FormatCellNumber),
              columnProperties: ({prop, model, data, column}) => {
                  return {
                  class: {
                      'revo-header': true
                  }
                  }
              },
              cellProperties: ({ prop, model, data, column }) => {
                  return {
                  style: getStyleGrid(prop, model, column)
                  }
              },
          },
          {
              "name": "vLY",
              "prop": "SOPwROvLY",
              "size": 100,
              "autoSize": true,
              cellTemplate: VGridVueTemplate(FormatCellNumber),
              columnProperties: ({prop, model, data, column}) => {
                  return {
                  class: {
                      'revo-header': true
                  }
                  }
              },
              cellProperties: ({ prop, model, data, column }) => {
                  return {
                  style: getStyleGrid(prop, model, column)
                  }
              },
          },
          {
              "name": "vsFCvLY",
              "prop": "SOPwROvLYmFCvLY",
              "size": 120,
              "autoSize": true,
              cellTemplate: VGridVueTemplate(FormatCellNumber),
              columnProperties: ({prop, model, data, column}) => {
                  return {
                  class: {
                      'revo-header': true
                  }
                  }
              },
              cellProperties: ({ prop, model, data, column }) => {
                  return {
                  style: getStyleGrid(prop, model, column)
                  }
              },
          }
        ]
      },
    ]
    
    const showEditRiskOpps = () => {
      vm.$bvModal.show(`executive-add-risk-opps-month`)
    }

    // subscription
    vm.$apollo.addSmartSubscription('GetExecutiveBrand', {
      query: gql`
      subscription GetExecutiveBrand($customWhere: json = null) {
        executiveBrand: function_get_executive_brand_v2 (args: {customWhere: $customWhere}) {
          id
          bpu
          brand
          year
          SOPEUR
          LYEUR
          FCEUR
          SOPvFC
          FCvLY
          RISKS
          OPPS
          SOPwRO
          SOPwROvLY
          SOPwROvLYmFCvLY
        }
      }`,
      variables(){
        return {
          customWhere: {
            "year":[`${2024}`,`${2024-1}`],
            "months": monthsSelected.value
          }
        }
      },
      result ({data}) {
        for(let obj of source.value) { if (obj.__typename) delete obj.__typename }

        id.value = data.executiveBrand[0].id
        source.value = data.executiveBrand
        isLoading.value = false
        isTableLoading.value = false

        // console.log(source.value)
        brandOptions.value = mapBrandOptions(source.value)
        // console.log(brandOptions)
      },
    })

    const toggleOption = (value) => {
      isTableLoading.value = true
      const index = monthsSelected.value.indexOf(value);
      if (index === -1) {
        monthsSelected.value.push(value);
      } else {
        monthsSelected.value.splice(index, 1);
      }

      // console.log('Months selected: ', monthsSelected.value)
    }

    const toggleHide = (bvEvent) => {
      console.log('here')
      bvEvent.preventDefault()
    }

    return {
      clearableSelect,
      source,
      id,
      columns,
      isLoading,
      isTableLoading,
      refGridSopExecutive,
      pluginRevo,

      showEditRiskOpps,

      monthsSelected,
      monthsOptions,
      brandOptions,

      toggleOption,
      toggleHide
    }
  },
}
</script>

<style lang="scss">
.revo-header {
  background: #1e347b;
  font-weight: bold;
  color: white;
  text-align: center;
  border-bottom: 3px solid #ebebeb;
  border-top: 3px solid #ebebeb;
}

.revo-header-quarter {
  background: #BFBFBF;
  font-weight: bold;
  color: black;
  text-align: center;
  border-top: 3px solid #ebebeb;
  border-bottom: 3px solid #ebebeb;
}

.table-wrapper {
  display: flex;
  height: 100vh;
  overflow: auto;
}

.table-wrapper .overlay {
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.vertical {
  min-width: none;
  width: 0px !important;
}


.title-month {
  font-size: 35px;
  font-weight: bold;
}

.grid-container-sop-executive {
  width: 100%;
  flex-grow: 1;
  font-family: "Segoe UI" !important;
  font-weight: bold;
  font-size: 15px;

  .temp-bg-range {
    display: block !important;
    position: absolute;
    pointer-events: none;
    z-index: 9;
    border: 1px solid #ff5e00;
    box-sizing: border-box;
  }

  .group-rgRow{
    display: none;
    margin-bottom: 17px;
  }

  .rgRow .disabled {
    background: none;
  }

  .rgHeaderCell.sortable:hover {
    background-color: #f1f1f1;
    color: black;
  }

  .header-rgRow:not(.group) {
    box-shadow: none;
  }

  revogr-header .header-rgRow.group {
    box-shadow: none;
  }

  revogr-data .rgRow  {
    box-shadow: none;
  }

  revogr-header .rgHeaderCell.focused-cell {
    color: black;
  }
}


/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.dropdownItem > .dropdown-item {
  width: 100% !important;
}

// #btnaddrisk{
//   background-color: #ff5e00;
//   color: white;
// }
// #btnsave{
//   background-color: green; 
//   color: white;
// }

@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
